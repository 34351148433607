import axios from "axios";
import QS from "qs";
import { app } from "@/main.js";
import { getStorage, removeAllStorage } from "@/utils/storage.js";
import { API } from "@/api/api.js";
import VueCookies from "vue-cookies"

// axios.defaults.headers.common['accept-language'] = getStorage('lang');

axios.interceptors.request.use(
	config => {
		let token = VueCookies.get('service-token');
		
		if(token) {
			config.headers = {
				"content-type": "application/json",
				"accept-language": getStorage('lang') === 'cn' ? 'zh-CN, zh' : 'en-US',
				"service-token": token
			};
		} else {
			config.headers = {
				"content-type": "application/json",
				"accept-language": getStorage('lang') === 'cn' ? 'zh-CN, zh' : 'en-US',
			};
		}
		config.url = `${process.env.VUE_APP_API}${config.url}`;
		
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	response => {
		// console.log("interceptors.response", response);
		let errorCode = response.data.error_code;
		if (errorCode === -999 || errorCode === -998) {
			removeAllStorage();
			Ajax(
				API.logout.method,
				API.logout.url,
			).then(result => {
				VueCookies.remove('account', null);
				VueCookies.remove('service-token', null);
				
				app.$router.push({ name: 'account.login' });
			});
		}
		
		return response.data;
	},
	error => {
		return Promise.reject(error);
	}
);

export const Ajax = async (method, url, params, isGeneral = true) => {
	let input = { method, url };
	let isGet = method.toLowerCase() === "get";
	if (isGet) {
		(input.params = {}),
			(input.paramsSerializer = params => {
				return QS.stringify(params, { arrayFormat: "repeat" });
			});
		!!params && (input.params = Object.assign({}, params));
	} else {
		input.data = params ? params : {};
	}
	// console.log("Ajax", JSON.stringify(input));
	const data = await axios(input)
		.then(response => {
			return response;
		})
		.catch(error => {
			console.log(error);
			let msg = {error: error.response.data ? error.response.data.error : error};
			return msg
		});
	return data;
};