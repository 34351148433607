import Vue from 'vue'
import Vuex from 'vuex'
import Login from './Login/index.js'

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		Login: Login
	}
})
