import Vue from 'vue'
import vuex from 'vuex'
import { Ajax } from "@/api/index.js"
import { API } from "@/api/api.js"
import { setStorage, getStorage, removeAllStorage } from "@/utils/storage.js"
import { urlReplace } from "@/utils/index.js"
import { app } from "@/main.js"
import VueCookies from 'vue-cookies'

export default {
	state:{
		isLogin: false,
		isAdmin: false,
		currency: 'USDT',
	},
	mutations: {
		setIsLogin(state, payload) {
			state.isLogin = payload;
		},
		setIsAdmin(state, payload) {
			state.isAdmin = payload;
		},
	},
	actions:{
		getStatus(context, payload) {
			return Ajax(
				API.accountStatus.method,
				API.accountStatus.url
			).then(result => {
				if(result.success !== 1) {
					return ;
				}
				context.commit('setIsLogin', !!result.data.login);
				context.commit('setIsAdmin', !!result.data.info.is_admin);
				// console.log('getStatus', !!result.data.info.is_admin)
				
				return result;
			});
		},
		login(context, payload) {
			
			return Ajax(
				API.login.method,
				API.login.url,
				payload
			).then(result => {
				if(result.success !== 1) {
					return result;
				}
				
				context.commit('setIsLogin', true);
				VueCookies.set('account', result.data.account, '30d', null);
				VueCookies.set('service-token', result.data.service_token, '30d', null);
				
				return result;
			});
		},
		logout(context, payload) {
			console.log('logout')
			return Ajax(
				API.logout.method,
				API.logout.url,
			).then(result => {
				if(result.success !== 1) {
					return ;
				}
				
				VueCookies.remove('account');
				VueCookies.remove('service-token');
				
				app.$router.push({ name: 'account.login' });
			});
		},
	},
}