<template>
	<div id="app">
		<div class="FM_wrapper">
			<Header></Header>
			<router-view/>
		</div>
	</div>
</template>

<script>
import Header from '@/components/share/Header.vue'
import VueCookies from 'vue-cookies'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
	data() {
		return {}
	},
	mounted() {
		let _this = this;
		/*
		 * set $Progress
		 */
		this.$Progress.finish();
	},
	computed: {
		...mapState(['Login']),
	},
	created() {
		/*
		 * remove touch zoom in mobile
		 */
		window.onload = () => {
			document.addEventListener('touchstart', (event) => {
				if (event.touches.length > 1) {
					event.preventDefault();
				}
			}, { passive: false });

			let lastTouchEnd = 0;
			document.addEventListener('touchend', (event) => {
				const now = (new Date()).getTime();
				if (now - lastTouchEnd <= 300) {
					event.preventDefault();
				}
				lastTouchEnd = now;
			}, false);
		};
		/*
		 * set $Progress
		 */
		this.$Progress.start();
		this.$router.beforeEach((to, from, next) => {
			if (to.meta.progress !== undefined) {
				let meta = to.meta.progress;
				this.$Progress.parseMeta(meta)
			}
			this.$Progress.start();
			next()
		});
		this.$router.afterEach((to, from) => {
			this.$Progress.finish()
		});

		/*
		 * GA
		 */
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());

		gtag('config', 'G-NV2060DFXP');
	},
	methods: {
		...mapActions(['getStatus']),
	},
	components: {
		Header,
	}
}
</script>
