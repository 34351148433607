//localstorage list
const storageList = {
	'service-token': 'service-token',
	'account': 'account',
	'balance': 'balance',
	'lang': 'lang'
};
//localstorage(set)
export const setStorage = (key, value) => {
	if (key && (value || typeof value === 'boolean' || value === 0) && storageList[key]) {
		localStorage.setItem(storageList[key], JSON.stringify(value));
	} else {
		console.error('儲存 localstorage 的格式錯誤，請確認有設定相關的數值');
	}
};

//localstorage(get)
export const getStorage = key => {
	if (key && storageList[key]) {
		let value = localStorage.getItem(storageList[key]);
		return value ? JSON.parse(value) : '';
	} else {
		console.error('讀取 localstorage 的格式錯誤，請確認有設定欲讀取的key值');
	}
};

//localstorage(remove)
export const removeStorage = key => {
	if (key && storageList[key]) {
		localStorage.removeItem(key);
	} else {
		console.error('欲刪除的 localstorage 格式有誤，請確認key值');
	}
};

//localstorage(remove all)
export const removeAllStorage = () => {
	let list = Object.keys(storageList);
	list.forEach(i => {
		if (i !== 'lang' && i !== 'refId') {
			localStorage.removeItem(storageList[i]);
		}
	});
};