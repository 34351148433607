import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { setStorage, getStorage } from "@/utils/storage.js"
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import VueProgressBar from 'vue-progressbar'
import VueApexCharts from 'vue-apexcharts'
import VueCookies from 'vue-cookies'
import moment from "moment"
import VueI18n from 'vue-i18n'
import en from '@/lang/en-us.json'
import cn from '@/lang/zh-cn.json'
import tw from '@/lang/zh-tw.json'
import $ from 'jquery'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import '@mdi/font/css/materialdesignicons.css'
import '@/styles/main.scss'
import VueClipboard from "vue-clipboard2"
import Pagination from 'vue-pagination-2'

Vue.config.productionTip = false;
Vue.use(VueProgressBar, {
	color: '#8705f9',
	failedColor: 'red',
	height: '2px'
});
Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(VueClipboard);
window.jQuery = $;
window.$ = $;
Vue.prototype.moment = moment;

// i18n
Vue.use(VueI18n);
let locale = 'en';
setStorage('lang', locale);
const i18n = new VueI18n({
	locale: locale,
	messages: { en, cn, tw }
});

// apex
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.component('pagination', Pagination);

export const app = new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app');
