//api version
let setURL = (url) => {
	return `${url}`;
};

export const API = {
	/*
	 * Account
	 */
	verifyRegister: {
		url: setURL("/accounts/verify/{code}"),
		method: "post"
	},
	login: {
		url: setURL("/accounts/login"),
		method: "post"
	},
	logout: {
		url: setURL("/accounts/logout"),
		method: "post"
	},
	accountStatus: {
		url: setURL("/accounts/status"),
		method: "get"
	},
	forgetPassword: {
		url: setURL("/accounts/forgot"),
		method: "post"
	},
	verifyForgetPassword: {
		url: setURL("/accounts/forgot/{code}"),
		method: "post"
	},
	changePassword: {
		url: setURL("/accounts/password/change"),
		method: "post"
	},
	verifyPassword: {
		url: setURL("/accounts/verify"),
		method: "post"
	},
	/*
	 * Invest
	 */
	fundList: {
		url: setURL("/funds"),
		method: "get"
	},
	fundInfo: {
		url: setURL("/funds/{id}"),
		method: "get"
	},
	fundPortfolio: {
		url: setURL("/funds/{id}/portfolio"),
		method: "get"
	},
	getBalanceInfo: {
		url: setURL("/balance"),
		method: "get"
	},
	/*
	 * Support
	 */
	support: {
		url: setURL("/supports/message"),
		method: "post"
	},
	/*
	 * WhiteList
	 */
	getWhitelist: {
		url: setURL("/admins/invitations"),
		method: "get"
	},
	addWhitelist: {
		url: setURL("/admins/invitations"),
		method: "post"
	},
	getInvitation: {
		url: setURL("/invitations/{code}"),
		method: "get"
	},
	confirmInvitation: {
		url: setURL("/invitations/{code}"),
		method: "post"
	},
};