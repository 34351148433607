import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import Store from '@/store/index.js'

Vue.use(VueRouter);

/*
 * for first time
 */
let isInit = false;
Store.dispatch('getStatus').then(() => {
	isInit = true;
});

/*
 * import {name} from {position}
 */
function loadView(view, path) {
	let hasPath = !!path;
	return () => hasPath ? import(`../views/${path}/${view}.vue`) : import(`../views/${view}.vue`);
}

/*
 * get token
 */
let tokenCheck = () => {
	let serviceToken = VueCookies.get('service-token');
	let account = VueCookies.get('account');
	let isLogin = !!(serviceToken && account);
	Store.commit('setIsLogin', isLogin);
	
	return isLogin;
};

let routerCheck = (to, from, next) => {
	let hasToken = tokenCheck();
	
	if (!isInit) {
		
		setTimeout(() => {
			routerCheck(to, from, next);
		}, 1000);
		
		return;
	}
	
	// console.log("routerCheck", to, from, hasToken);
	switch (to.name) {
		case "account.login":
			hasToken && next("/");
			hasToken || next();
			break;
		case "account.forgetPassword":
			hasToken && next("/");
			hasToken || next();
			break;
		case "admin.whiteList":
			if (Store.state.Login.isAdmin && hasToken) {
				next();
			} else {
				next("/404");
			}
			
			break;
		default:
			// console.log('default', hasToken)
			hasToken && next();
			hasToken || next("/account/login");
		
	}
};

const routes = [
	{
		path: '/',
		name: 'home',
		component: loadView("Home")
	},
	{
		path: '/support',
		name: 'support',
		component: loadView("Support")
	},
	{
		path: '/account',
		name: 'account',
		redirect: '/account/login',
		component: loadView("Index", "Account"),
		children: [
			{
				path: "login",
				name: "account.login",
				beforeEnter: routerCheck,
				component: loadView("Login", "Account")
			},
			{
				path: "forgetPassword",
				name: "account.forgetPassword",
				beforeEnter: routerCheck,
				component: loadView("ForgetPassword", "Account")
			},
			{
				path: "reset/:code",
				name: "account.resetPassword",
				component: loadView("ResetPassword", "Account")
			},
			{
				path: "verify/:code",
				name: "account.verify",
				component: loadView("Verify", "Account")
			},
			{
				path: "changePassword",
				name: "account.changePassword",
				beforeEnter: routerCheck,
				component: loadView("ChangePassword", "Account")
			},
			// {
			// 	path: "changeEmail",
			// 	name: "account.changeEmail",
			// 	beforeEnter: routerCheck,
			// 	component: loadView("ChangeEmail", "Account")
			// },
		]
	},
	{
		path: '/invest',
		name: 'Invest',
		redirect: '/invest/list',
		component: loadView("Index", "Invest"),
		children: [
			{
				path: "list",
				name: "invest.list",
				beforeEnter: routerCheck,
				component: loadView("List", "Invest")
			},
			{
				path: "detail/:id",
				name: "invest.detail",
				beforeEnter: routerCheck,
				component: loadView("Detail", "Invest")
			},
		]
	},
	{
		path: '/admin',
		name: 'Admin',
		redirect: '/admin/whiteList',
		component: loadView("Index", "Admin"),
		children: [
			{
				path: "whiteList",
				name: "admin.whiteList",
				beforeEnter: routerCheck,
				component: loadView("WhiteList", "Admin")
			},
		]
	},
	{
		name: '404',
		path: '/404',
		component: loadView("PageNotFound")
	},
	{
		path: "*",
		redirect: '/404'
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router
