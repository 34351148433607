import { setStorage, getStorage, removeStorage, removeAllStorage } from "@/utils/storage.js"

export const urlReplace = (url, obj) => {
	let checkUrl = typeof url === "string" && url.length !== 0;
	let checkObj =
		typeof obj === "object" &&
		!Array.isArray(obj) &&
		Object.keys(obj).length !== 0;
	// console.log("fff", url, obj, checkUrl, checkObj);
	if (checkUrl && checkObj) {
		Object.keys(obj).forEach(i => {
			let regExp = new RegExp(`{${i}}`, "g");
			let replaceText = obj[i];
			url = url.replace(regExp, replaceText);
		});
		return url;
	} else {
		console.error("格式有誤，請自行確認");
	}
};

// 補小數點至指定位數
export const paddingDecimal = (str, length = 2) => {
	let format = parseFloat(str).toFixed(length);
	return format === "NaN" ? str : format;
};

// 深層複製資料
export const deepCopy = object => {
	let info = "";
	!!object && (info = JSON.parse(JSON.stringify(object)));
	return info;
};

// 產生隨機16字串
export const generateOrderID = (length = 16) => {
	// console.log('generateOrderID')
	let result = '';
	let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const formatNumber = (amount) => {
	let str = amount.toString().split(".");
	str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return str.join(".");
}