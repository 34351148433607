<template>
	<div class="FM_headerBox" v-if="Login.isLogin !== false && currentRouteName !== 'home' && currentRouteName !== 'account.login' && currentRouteName !== 'account.verify' && currentRouteName !== 'account.forgetPassword' && currentRouteName !== 'account.resetPassword' && currentRouteName !== 'account.changePassword' && currentRouteName !== '404'">
		<nav class="navbar navbar-expand-lg navbar-dark">
			<button type="button" class="FM_btn-menu hidden-lg hidden-xl" @click="$router.go(-1)">
				<span class="mdi mdi-arrow-left mdi-18px"></span>
			</button>
			<router-link to="/" class="navbar-brand FM_logo-header">
				<img src="~@/assets/logo/logo-h.png" class="img-logo" width="165">
			</router-link>
			<button class="navbar-toggler FM_btn-menu" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="mdi mdi-menu"></span>
			</button>

			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav mr-auto">
					<li class="nav-item">
						<router-link class="nav-link" to="/">Home</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/invest/list">Funds</router-link>
					</li>
					<li class="nav-item" v-if="this.Login.isAdmin">
						<router-link class="nav-link" to="/admin/whitelist">Whitelist</router-link>
					</li>
					<li class="nav-item">
						<a class="nav-link" @click="logoutBtn">Logout</a>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</template>

<script>
import { Ajax } from "@/api/index.js"
import { API } from "@/api/api.js"
import { app } from "@/main.js"
import { mapState, mapActions, mapMutations } from 'vuex'
import { setStorage, getStorage, removeAllStorage } from "@/utils/storage.js"

export default {
	name: "Header",
	data() {
		return {

		}
	},
	created() {

	},
	mounted() {

	},
	computed: {
		...mapState(['Login']),
		currentRouteName() {
			return this.$route.name;
		},
	},
	methods: {
		...mapActions(['logout']),
		logoutBtn() {
			this.$store.dispatch('logout');
		}
	},
	components: {

	}
}
</script>

<style scoped>

</style>
